body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  * {
    scrollbar-width: medium;
    scrollbar-color: transparent var(--primary);
  }
  
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 0;
    border: none;
  }